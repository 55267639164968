import { createContext, useContext, useEffect, useState } from 'react'

import { TCartItems } from '@contexts/Cart/types'
import { useUserContext } from '@contexts/User'

import { api } from '@services'

import helpers from './helpers'

import { TPaymentMethod, TPurchaseHistory } from '@customTypes/entities'

import { TContext, TCurrentPayment, TTickets } from './types'

const PurchaseContext = createContext<TContext>({
	history: [],
	gotHistory: false,
	currentPayment: {},
	getByCampaignId: () => undefined,
	finalize: async () => {},
	waitPayment: async () => {},
	sendToEmail: async () => {},
})

export const PurchaseProvider = ({ children }: React.PropsWithChildren) => {
	const { isAuthenticated } = useUserContext()

	const [history, setHistory] = useState<TTickets>([])
	const [gotHistory, setGotHistory] = useState(false)
	const [currentPayment, setCurrentPayment] = useState<TCurrentPayment>({})

	const getByCampaignId = (id: string) => history.find((history) => history.campaignId === id)

	const sendToEmail = () => api.payment.sendToEmail()

	const waitPayment = () => {
		return new Promise<void>((resolve) => {
			const intervalId = setInterval(async () => {
				const paymentStatus = await api.payment.getPaymentStatus(currentPayment?._id!)
				if (paymentStatus.status === 'PAID') {
					clearInterval(intervalId)
					resolve()
				}
			}, 10000)
		})
	}

	const finalize = (cart: TCartItems, method: TPaymentMethod) => {
		const payments = cart.map(({ _id, tickets }) => ({ campaignId: _id, numTickets: tickets }))
		return api.payment.finalize(payments, method).then(setCurrentPayment)
	}

	const mountHistory = (purchases: TPurchaseHistory) => {
		setHistory(helpers.getPaidPurchases(purchases))
		setGotHistory(true)
	}

	useEffect(() => {
		if (isAuthenticated) api.payment.getPurchaseHistory().then(mountHistory)
	}, [isAuthenticated])

	return (
		<PurchaseContext.Provider
			value={{
				history,
				gotHistory,
				currentPayment,
				getByCampaignId,
				finalize,
				waitPayment,
				sendToEmail,
			}}
		>
			{children}
		</PurchaseContext.Provider>
	)
}

export const usePurchaseContext = () => useContext(PurchaseContext)
