import { createContext, useContext, useEffect, useState } from 'react'

import { api } from '@services'

import helpers from './helpers'

import { TCartItem, TCartItems, TContext } from './types'

const CartContext = createContext<TContext>({
	items: [],
	quantity: { items: 0, tickets: 0 },
	price: 0,
	discountedPrice: 0,
	add: () => {},
	remove: () => () => {},
	update: () => () => {},
	reset: () => {},
	recalculateDiscount: () => {},
})

export const CartProvider = ({ children }: React.PropsWithChildren) => {
	const [items, setItems] = useState<TCartItems>([])

	const [ticketsCount, price, discountedPrice] = items.reduce(helpers.toCountAndPrice, [0, 0, 0])

	const quantity = {
		items: items.length,
		tickets: ticketsCount,
	}

	const update = (id: string) => (amount: number) => {
		setItems((prevCart) => {
			const index = prevCart.findIndex((item) => item._id === id)
			const item = prevCart[index]

			if (item) {
				if (amount <= item.limitTickets) item.tickets = amount
				if (amount <= 0) item.tickets = 0
			}

			return [...prevCart]
		})
	}

	const add = (newItem: TCartItem) => {
		const alreadyAddedItem = items.find((item) => item._id === newItem._id)

		if (alreadyAddedItem) {
			const handleUpdate = update(newItem._id)
			handleUpdate(alreadyAddedItem.tickets + newItem.tickets)
		} else {
			setItems((prevCart) => [...prevCart, newItem])
		}
	}

	const remove = (id: string) => () => {
		setItems((prevCart) => prevCart.filter((item) => item._id !== id))
	}

	const reset = () => setItems([])

	const recalculateDiscount = async () => {
		const promises = items.map((item) => api.campaign.checkDiscount(item._id))
		const discounts = await Promise.all(promises)
		setItems((prevCart) =>
			prevCart.map((item, index) => ({
				...item,
				useDiscountFirstUsers: discounts[index].applyDisccount,
			}))
		)
	}

	return (
		<CartContext.Provider
			value={{
				items,
				quantity,
				price,
				discountedPrice,
				add,
				remove,
				update,
				reset,
				recalculateDiscount,
			}}
		>
			{children}
		</CartContext.Provider>
	)
}

export const useCartContext = () => useContext(CartContext)
