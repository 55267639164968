import { createContext, useContext, useEffect, useState } from 'react'

import { api } from '@services'

import { TCampaigns } from '@customTypes/entities'

import { TContext } from './types'

const CampaignContext = createContext<TContext>({
	campaigns: [],
	noCampaigns: false,
	getById: () => undefined,
})

export const CampaignProvider = ({ children }: React.PropsWithChildren) => {
	const [campaigns, setCampaigns] = useState<TCampaigns>([])
	const [noCampaigns, setNoCampaigns] = useState(false)

	const getById = (id?: string) => campaigns.find((campaigns) => campaigns._id === id)

	useEffect(() => {
		api.campaign.getAll({ query: { status: 'STARTED' }, limit: 10 }).then((data) => {
			if (Boolean(data.length)) setCampaigns(data)
			else setNoCampaigns(true)
		})
	}, [])

	return (
		<CampaignContext.Provider value={{ campaigns, noCampaigns, getById }}>
			{children}
		</CampaignContext.Provider>
	)
}

export const useCampaignContext = () => useContext(CampaignContext)
