import { createContext, useContext, useEffect, useState } from 'react'

import { api } from '@services'

import helpers from './helpers'

import { DEFAULT_CUSTOMIZATIONS } from './constants'

import { TContext } from './types'

const CustomizationContext = createContext<TContext>({
	isLoading: false,
	customizations: DEFAULT_CUSTOMIZATIONS,
	setupCustomizations: async () => {},
})

export const CustomizationProvider = ({ children }: React.PropsWithChildren) => {
	const [customizations, setCustomizations] = useState(helpers.getStoredCustomizations())
	const [isLoading, setIsLoading] = useState(false)

	const setupCustomizations = async () => {
		setIsLoading(true)

		const apiCustomizations = await api.customization.getData()
		const storedCustomizations = helpers.getStoredCustomizations()
		const currentCustomizations = apiCustomizations || storedCustomizations || DEFAULT_CUSTOMIZATIONS

		helpers.saveCurrentCustomizations(currentCustomizations)
		setCustomizations(currentCustomizations)
		setIsLoading(false)
	}

	useEffect(() => {
		setupCustomizations()
	}, [])

	useEffect(() => {
		helpers.createColors(customizations)
	}, [customizations])

	return (
		<CustomizationContext.Provider value={{ isLoading, customizations, setupCustomizations }}>
			{children}
		</CustomizationContext.Provider>
	)
}

export const useCustomizationContext = () => useContext(CustomizationContext)
