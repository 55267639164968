import { createContext, useContext, useState } from 'react'

import { TContext, THistory, TModalContentName } from './types'

// TODO update state management
/*

Agora o estado sera um objeto, com cada chave sendo o nome de um modal e seu o valor sendo um objeto com as propriedades do estado do modal
Cada modal terá o seu estado fortemente tipado
Criar função no Context para que cada modal pegue seu estado

Objetivo de persistir o estado de cada modal do histórico

*/
const ModalContext = createContext<TContext>({
	isOpen: false,
	content: undefined,
	state: {},
	open: () => () => {},
	close: () => {},
	navigate: () => () => {},
	previous: () => {},
})

export const ModalProvider = ({ children }: React.PropsWithChildren) => {
	const [isOpen, setIsOpen] = useState(false)
	const [history, setHistory] = useState<THistory>([])
	const content = history.length ? history[history.length - 1] : undefined

	const [state, setState] = useState<GenericObject>({})
	const updateState = (newState?: GenericObject) => setState(newState ?? {})

	const open = (contentName: TModalContentName, newState?: GenericObject) => () => {
		if (contentName) {
			setIsOpen(true)
			setHistory([contentName])
			updateState(newState)
		}
	}

	const close = () => {
		setIsOpen(false)
		setHistory([])
		updateState()
	}

	const navigate = (contentName: TModalContentName, newState?: GenericObject) => () => {
		if (contentName) {
			setHistory((prevHistory) => [...prevHistory, contentName])
			updateState(newState)
		}
	}

	const previous = () => {
		if (history.length > 1) setHistory((prevHistory) => prevHistory.slice(0, -1))
		else close()
	}

	return (
		<ModalContext.Provider value={{ isOpen, content, state, open, close, navigate, previous }}>
			{children}
		</ModalContext.Provider>
	)
}

export const useModalContext = () => useContext(ModalContext)
